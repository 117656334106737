
export const config = {
  buildNumber: process.env.NEXT_PUBLIC_BUILD_NUMBER || 'LOCAL',
  authSecret: process.env.authSecret || 'secret',
  muiXLicenseKey: process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY || '',
  defaultMapCenter: { lat: 38.8864, lng: -98.5795 },
  defaultMapZoom: 5,
  minZoom: 3,
  routes: {
    rootPageUrl: '/',
    homePageUrl: '/app/dashboard',
    loginPageUrl: '/auth/login',
    fleetsPageUrl: '/app/fleets',
    jobsPageUrl: '/app/jobs/log',
    videoPlaybackPageUrl: '/app/video-playback',
    videoPlayerPageUrl: '/app/video-playback/video-player',
    videoQueriesPageUrl: '/app/jobs/video-queries',
    ridershipQueriesPageUrl: '/app/jobs/ridership-queries',
    geographicalQueriesPageUrl: '/app/jobs/geographical-queries',
    markedVideoPageUrl: '/app/jobs/marked-video',
    userPreferencesPageUrl: '/app/user-preferences',
    userManagementPageUrl: '/app/user-management',
    changePasswordPageUrl: '/app/change-password',
    vehicleDatabasePageUrl: '/app/database/vehicle-database',
  },
};
