import { AnyAction } from '@reduxjs/toolkit';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import {
  BaseQueryFn, createApi, FetchArgs, fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { StorageAuthKeys } from '@th-common/constants/auth';
import { THttpStatusCode } from '@th-common/enums/http/http-status-code';
import { reduxTags } from '@th-common/enums/redux-tags.enum';
import { IAuthResponse } from '@th-common/interfaces/auth';
import { StorageUtils } from '@th-common/utils';
import { Mutex } from 'async-mutex';
import { HYDRATE } from 'next-redux-wrapper';

import { initializeAuth, logout } from './auth/slice';
import { handleErrorNotification, IBaseQueryExtraOptions } from './handlers';
import { RootState } from './store';

type TBaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, IBaseQueryExtraOptions, FetchBaseQueryMeta>;

const mutex = new Mutex();

export const baseQuery: TBaseQuery = fetchBaseQuery({
  baseUrl: '',
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = (getState() as RootState).auth.token;
    const excludeBearer = [
      'getVideoDownload',
      'getVideoDownloadTrackGpsData',
      'getVideoDownloadEventsData',
    ].includes(endpoint);
    if (token && !excludeBearer) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
  credentials: 'include',
});
const baseQueryWithReAuth: TBaseQuery =
  async (args, api, extraOptions) => {
    const baseUrl = StorageUtils.search<{ apiBaseUrl: string }>('env', 'sessionStorage')?.apiBaseUrl;
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    const parsedArgs = typeof args === 'string' ? `${baseUrl}/${args}` : { ...args, url: `${baseUrl}/${args.url}` };
    let result = await baseQuery(parsedArgs, api, extraOptions);
    if (result.error) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        if (result.error.status === THttpStatusCode.Unauthorized) {
          try {
            // try to get a new token
            const refreshTokenStorage = (api.getState() as RootState).auth.refreshToken;
            const { data: refreshTokenResult } = await baseQuery(
              `${baseUrl}/token/renew?refreshToken=${refreshTokenStorage}`,
              api,
              extraOptions,
            );
            if (refreshTokenResult) {
              const storageIsRemember = StorageUtils.search<boolean>(StorageAuthKeys.IS_REMEMBER);

              const {
                token,
                refreshToken,
                expiresIn,
                authenticationMethod,
                features,
              } = refreshTokenResult as IAuthResponse;
              api.dispatch(
                initializeAuth({
                  token,
                  refreshToken,
                  expiresIn: Math.round(Date.now() / 1000) + expiresIn,
                  authenticationMethod,
                  features,
                  isRemember: storageIsRemember,
                }),
              );
              // retry the initial query
              result = await baseQuery(parsedArgs, api, extraOptions);
            } else {
              api.dispatch(logout());
            }
          } catch {
            api.dispatch(logout());
          }
        } else {
          const errorData = handleErrorNotification(api, result.error, extraOptions);

          if (errorData) {
            result.error.data = {
              ...errorData,
            };
          }
        }

        // release must be called once the mutex should be released again.
        release();
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(parsedArgs, api, extraOptions);
      }
    }
    return result;
  };

// Define an empty API so modules define endpoints independently
// See https://redux-toolkit.js.org/rtk-query/usage/code-splitting for details
export const apiRoot = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  extractRehydrationInfo(action: AnyAction) {
    if (action.type === HYDRATE) {
      return action.payload.api;
    }
  },
  tagTypes: reduxTags,
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
});
